import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PrimaryCta from "../components/primary-cta"
import Checkmark from "../assets/check-mark.svg"


class PageContact extends Component {
  render() {
    const {
      titre,
      body,
      sousTitre,
      sousBodyRich,
      headingImage,
      formulaireContactTitre,
      descriptionFormulaireContact,
      imageFormulaireContact,
      tarifs,
      cta
    } = this.props.data.contentfulPageContact

    const path = this.props.location ? this.props.location.pathname : null
    const annonce_slug = this.props.location.state ? this.props.location.state['slug'] : null;

    return (
      <Layout path={path}>
        <PageHeader title={titre} body={body} sousBody={sousBodyRich && sousBodyRich} sousTitre={sousTitre} headingImage={headingImage} cta={cta}/>
        <div id="page-contact">
          {tarifs && (
            <div id="block" className="page-contact--tarifs">
              <div id="tarifs-header">
                <h3>{tarifs.titre}</h3>
                <p>{tarifs.body}</p>
              </div>
              <div id="tarifs-container">
                <Tarif tarif={tarifs.prix[0]}/>
                <span className="tarif-plus">+</span>
                <Tarif tarif={tarifs.prix[1]}/>
              </div>
            </div>
          )}
          <div id="block">
            <div className="page-contact--form-text">
              <GatsbyImage image={getImage(imageFormulaireContact)} imgStyle={{ objectFit: "contain" }} alt={imageFormulaireContact.description} className="img"/>
              <p dangerouslySetInnerHTML={{ __html: descriptionFormulaireContact.descriptionFormulaireContact }}/>
            </div>
            <form className="page-contact--form" method="post" action="https://formspree.io/f/xeqdrkgw">
              <h3>{formulaireContactTitre}</h3>
              {
                annonce_slug && (
                  <input className="page-contact--form--annonce-input" type="text" placeholder="Annonce" value={annonce_slug} name="slug" disabled/>
                )
              }
              <input className="page-contact--form--generic-input" type="text" placeholder="Nom"  name="nom" required/>
              <input className="page-contact--form--generic-input" placeholder="Email" type="email" name="_replyto" required/>
              <input className="page-contact--form--generic-input" type="text" placeholder="Téléphone" name="telephone" required/>
              <textarea className="page-contact--form--generic-input" placeholder="Message" name="message"/>
              <div className="cta-container">
                <button id="primary-cta" className={"button button--animated"} type="submit"><span>Envoyer</span></button>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

const Tarif = ({ tarif }) => (
  <div id="tarif-container">
    <h4>{tarif.title}</h4>
    <span className="tarif-container--divider"/>
    <p>{tarif.description}</p>
    <ul>
      {tarif.options.map((object, i) => (
        <div className="tarif-container--row">
          <Checkmark className="tarif-container--checkmark"/>
          <li>{object}</li>
        </div>
      ))}
    </ul>
  </div>
)



PageContact.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageContact

export const pageContactQuery = graphql`
  query pageContactQuery($slug: String!) {
    contentfulPageContact(slug: { eq: $slug }) {
      titre
      slug
      sousTitre
      sousBodyRich {
        raw
      }
      cta
      headingImage {
        description
        gatsbyImageData(
          layout: FULL_WIDTH
          quality:100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      formulaireContactTitre
      descriptionFormulaireContact {
        descriptionFormulaireContact
      }
      imageFormulaireContact{
        description
        gatsbyImageData(
          layout: FULL_WIDTH
          quality:100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      tarifs {
        titre
        body
        prix {
          title
          description
          options
        }
      }
    }
  }
`
